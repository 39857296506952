/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
    border-radius: .5rem;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    right: 10px;
    top: 5px;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .add-list-form {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    margin-top: 20px;
  }

  .add-list-form label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .add-list-form input {
    width: 100%;
    padding: 12px;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .add-list-form button {
    font-size: 16px;
    border-radius: 5px;
    padding: 12px;
    margin-top: 20px;
    height: 45px;
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: #E16339;
  }

  .add-list-form button:hover {
    background-color: #9e4528;
  }