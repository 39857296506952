.lists-container {
  width: 100%;
  display: flex;
  padding: 20px;
}

.lists-container ul {
  background-color: lightgrey;
  padding: 15px;
  border-radius: 10px;
}

.lists-container ul li {
  text-decoration: none;
  list-style-type: none;
}

.sidenav{
  position: fixed;
  width: 300px;
  height: 100%;
  left: 0;
  top: 0;
  padding-top: 70px;
  background-color: #E16339;
  overflow: hidden;

  .main-buttons{
    list-style-type: none;
    padding: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    
    li{
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-weight: 600;
      width: 100%;
      padding: 20px;
    }
    li:hover {
      background-color: #cb5933;
    }

    li.selected {
      background-color: #cb5933;
    }
    @media only screen and (max-width: 600px) {
      li {
        font-size: 0;
      }
      li:first-letter {
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .sidenav {
    max-width: 15%;
    z-index: 2;
  }
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 80%;
  margin-top: 20px;
  padding-bottom: 20px;
  border-collapse: separate;
  border-spacing: 0 10px;
  border-radius: .5rem;
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
@media only screen and (max-width: 900px) {
  .close-on-mobile{
      display: none;
  }
}

table div.row {
  /* background-color: green; */
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  flex-direction: column;
}
tr {
  width: 100%;
  display: flex;
  margin-top: 10px;
}

tr.main-row td, th {
  /* border: 1px solid #dddddd; */
  /* width: 100%; */
  text-align: center;
  vertical-align: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  border-bottom: 1px solid grey;
  /* margin: 8px; */
  text-wrap: nowrap;
}

tr.main-row:hover {
  border-bottom: 1px solid #E16339;
  cursor: pointer;
}


/* tr:nth-child(even) {
  background-color: #dddddd;
} */
table td:last-child {
  width: 100%;
  text-align: end;
  align-items: center;
}

table input[type="text"]{
    /* margin: 20px; */
    width: calc(100% - 20px);
    padding: 12px;
    margin-top: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding-left: 60px;
    height: 45px;
}

.expandable-row {
  display: none;
}

.expandable-row.expanded {
  display: block;
}

.input-box {
  position: relative;
  display: flex;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.input-box button {
  font-size: 16px;
  border-radius: 5px;
  padding: 12px;
  margin-top: 20px;
  height: 45px;
  border: none;
  cursor: pointer;
  color: #fff;
  background-color: #E16339;
}

.input-box button:hover {
  background-color: #cb5933;
  color: #fff;
}

.input-box .prefix {
  position: absolute;
  top: 48%;
  left: 20px;
  transform: translateY(-50%) translateX(50%);
  font-size: 20px;
  color: #E16339;
  /* z-index: inherit; */
}

/* The container */
.checkmark-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  /* min-width: 80px; */
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1;}

.dropdown:hover .dropdown-content {
  display: block;
}

.subtasks-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 8px;
  padding-right: 8px;
  /* padding-left: 20px; */
}

.subtasks-container li {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 8px;
}
.subtasks-container li::marker {
  content: "✓";
}

.subtask-checkbox-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

p.completed {
  text-decoration: line-through;
}

.subtask-input {
  width: 30%;
  padding: 8px;
  border: 1px solid lightgray;
  border-radius: 5px;
  font-size: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

#remove-list-btn svg:hover {
  cursor: pointer;
  path {
    stroke: red;
  }
}
