#layout header {
    width: 100%;
    height: 70px;
    /* background-color: #333; */
    color: #7B7B7B;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    z-index: 2;
    position: fixed;
}

#layout nav ul.nav {
    display: flex;
    gap: 20px;
}

#layout nav ul {
    display: flex;
    gap: 0;
}

#layout nav ul li {
    text-decoration: none;
    list-style-type: none;
}

#layout nav ul li a {
    color: #7B7B7B;
    text-decoration: none; 
}