  
  .loader {
    position: absolute;
    top: 50%;
    left: 40%;
    margin-left: 10%;
    transform: translate3d(-50%, -50%, 0);
  }
  
  .dot {
    width: 30px;
    height: 30px;
    /* background: #3ac; */
    border-radius: 100%;
    display: inline-block;
    animation: slide 1s infinite;
  }
  
  .dot:nth-child(1) {
    animation-delay: 0.1s;
    background: rgba(225, 99, 57, 0.5);
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
    background: rgba(225, 99, 57, 0.6);
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.3s;
    background: rgba(225, 99, 57, 0.7);
  }
  
  .dot:nth-child(4) {
    animation-delay: 0.4s;
    background: rgba(225, 99, 57, 0.8);
  }
  
  .dot:nth-child(5) {
    animation-delay: 0.5s;
    background: rgba(225, 99, 57, 0.9);
  }
  
  @keyframes slide {
    0% {
      transform: scale(1);
    }
    50% {
      opacity: 0.3;
      transform: scale(2);
    }
    100% {
      transform: scale(1);
    }
  }
  